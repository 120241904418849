<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem>
          <span>仓库名称：</span>
          <Select class="iviewIptWidth250 marginRight60" v-model="warehouse_id" filterable clearable @on-select="warehouseChange">
            <Option v-for="(item, index) in storeList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>产品名称：</span>
          <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" :disabled="warehouse_id < 0" v-model="queryFrom.product_id" clearable filterable>
            <Option v-for="(item, index) in productList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>规格型号/SKU：</span>
          <Select class="iviewIptWidth250" :disabled="warehouse_id < 0 || !queryFrom.product_id" multiple filterable clearable v-model="queryFrom.specification_id_str" :max-tag-count="queryFrom.specification_id_str.length == 1 ? 1 : 0">
            <Option v-for="(item, index) in modelList" :value="item.specification_id" :key="index" :label="item.model_name + '-' + item.item_number">
              <span>{{ item.model_name }}</span>
              <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
            </Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number marginRight0" :label-width="10">
          <span class="pageBtn finger btnSure" @click="queryProductLookup">查询</span>
          <span class="pageBtn finger btnSure marginL" @click="warehouseOutput">导出</span>
        </FormItem>
      </Form>
      <Table :columns="listColumns" :row-class-name="rowClassName" :data="meterList" border no-data-text="暂无产品">
        <template slot-scope="{ row, index }" slot="check_quantity">
          <!--          <i-input placeholder="请输入" type="number" :value="row.check_quantity" @on-change="changeIpt($event, 'check_quantity', index)"></i-input>-->
          <InputNumber :max="999999" :min="0" :precision="0" :value="row.check_quantity" :active-change="false" @on-change="changeIpt($event, 'check_quantity', index)"></InputNumber>
        </template>
      </Table>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0">
        <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
      </div>
      <div class="summary" v-if="totalMoney > 0 && allNum > 0">
        <div>
          <span style="margin-right: 20px;"
            >总数量：<span class="color389">{{ allNum }}</span></span
          >
          <span>合计金额：</span>
          <span class="color389">¥{{ totalMoney }}</span>
        </div>
      </div>
      <div class="tipsDiv">
        <div class="tipsFl">备注</div>
        <div class="tipsFr">
          <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model="postFrom.note"></i-input>
        </div>
      </div>
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnSure marginLeft20 fr" @click="save">提交</span>
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
    <!--    弹窗选择数据-->
    <Modal @on-cancel="clearSearch" v-model="chooseStatus" label-position="left" width="1000" :footer-hide="true" class-name="vertical-center-modal" title="产品列表">
      <div v-if="chooseStatus">
        <Table :loading="resultLoading" :columns="chooseColumns" :data="chooseList" border @on-selection-change="selectChange" @on-select-all="selectChangeAll"></Table>
        <div style="margin: 20px 0px;" class="tabPages" v-if="chooseList !== null && chooseList.length > 0 && total">
          <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
          <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
          <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
        </div>
        <div class="clearfix" style="margin-top: 60px;">
          <span v-if="!btnLoading" class="pageBtn finger btnSure fr" @click="sureChoose">
            <span>确认</span>
          </span>
          <span v-if="btnLoading" class="pageBtn finger btnSure fr">
            <Spin style="display: inline-block; color: white;">
              加载中...
            </Spin>
          </span>
        </div>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
    <!--    有产品数据时 切换别的供应商-->
    <Modal v-model="changeSupplierStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否更换仓库名称？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="sureChangeSupplier">确定</span>
          <span class="pageBtn finger btnCancle" @click="cancleChangeSupplier">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import TipsComponent from '@/components/tipsComponent'
import { mapState } from 'vuex'
export default {
  name: 'addInventoryStockConsign',
  components: {
    TipsComponent,
  },
  computed: {
    ...mapState(['clientNameList']),
    allNum() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.product_amount * 1
      })
      return totle
    },
    totalMoney() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.product_total_price * 1
      })
      return totle.toFixed(2)
    },
  },
  data() {
    return {
      warehouse_name: '',
      outputlist: [],
      storeList: [],
      warehouse_id: '-1',
      changeSupplierStatus: false,
      id: '',
      saveIndex: '', // 保存上一次的index
      postStatus: false,
      deleteStatus: false,
      queryFrom: {
        warehouse_id: '',
        product_id: '',
        specification_id_str: [],
        warehouse_name: '',
      },
      postFrom: {
        warehouse_id: '',
        line_info: [],
        note: '',
      },
      productList: [],
      modelList: [],
      meterList: [], // 已选产品列表
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '物料号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 120,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 120,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          width: 120,
        },
        {
          title: '序列号',
          key: 'serial_number',
          align: 'center',
          width: 120,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          width: 130,
        },
        {
          title: '单价',
          align: 'center',
          width: 120,
          render: (h, param) => {
            return h('div', [h('span', param.row.unit_price ? '¥' + param.row.unit_price : 0)])
          },
        },
        {
          title: '库存数量',
          key: 'inventory_quantity',
          align: 'center',
          width: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 100,
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
          width: 100,
        },
        {
          title: '实际盘存数量',
          slot: 'check_quantity',
          align: 'center',
          width: 150,
        },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      chooseStatus: false,
      chooseList: [],
      chooseColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 55,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 150,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 120,
        },
        {
          title: '库存数量',
          key: 'inventory_quantity',
          align: 'center',
          width: 180,
        },
        // {
        //   title: '单位',
        //   key: 'product_unit',
        //   align: 'center',
        //   width: 95,
        // },
      ],
      hadSelectList: [], // 弹窗已选数据
      clickIndex: Number, // 暂存点击删除数据下标
      detailsInfo: {},
      resultLoading: false,
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      btnLoading: false,
      debugg: false,
    }
  },
  mounted() {
    this.queryStoreList()
    this.id = this.$route.query.id || ''
    if (this.id) {
      this.queryDetails(this.id)
    }
  },
  methods: {
    //关闭弹窗时的变化
    clearSearch() {
      this.pages.page = 1
      this.total = 0
    },
    warehouseOutput() {
      if (this.warehouse_id == -1) {
        this.$Message.warning('请选择仓库名称')
        return
      }
      let arr = []
      for (let i = 0; i < this.meterList.length; i++) {
        arr.push(this.meterList[i].id)
      }
      let data = {
        warehouse_id: this.warehouse_id,
        product_id: this.queryFrom.product_id,
        specification_id_str: this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : '',
      }
      this.$http.downFile(this.$apiConsign.warehouseOrderExport, data, true).then(res => {
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = this.warehouse_name + '盘存表.xlsx'
        aLink.click()
      })
    },
    // 分页
    changePage(e) {
      this.pages.page = e
      this.queryProductLookup()
    },
    rowClassName(row, index) {
      if (row.inventory_quantity > row.check_quantity) {
        return 'greenRow'
      } else if (row.inventory_quantity < row.check_quantity) {
        return 'redRow'
      } else {
        return ''
      }
    },
    back() {
      this.$router.go(-1)
    },
    // 查询仓库列表
    queryStoreList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
        is_consign:1
      }
      this.$http.get(this.$apiConsign.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 客户名称改变
    warehouseChange(e) {
      this.warehouse_name = e.label
      //  先判断有没有已选产品列表
      if (this.meterList.length > 0) {
        this.changeSupplierStatus = true
      } else {
        if (e.value >= 0) {
          this.queryFrom.product_id = ''
          this.queryFrom.specification_id_str = []
          this.saveIndex = e.value
          this.warehouse_id = e.value
          this.warehouse_name = e.label
          this.queryProduct(e.value)
        } else {
          this.queryFrom.product_id = ''
          this.queryFrom.specification_id_str = []
        }
      }
    },
    // 在已有产品的情况下 确认更换供应商
    sureChangeSupplier() {
      this.changeSupplierStatus = false
      this.queryFrom.product_id = ''
      this.queryFrom.product_code_array = []
      this.productList = []
      this.meterList = []
      this.queryProduct('', this.clientNameList[this.supplierIndex].customer_id, this.clientNameList[this.supplierIndex].customer_type)
    },
    // 在已有产品的情况下 取消更改供应商
    cancleChangeSupplier() {
      this.warehouse_id = this.saveIndex
      this.changeSupplierStatus = false
    },
    // 确认删除
    sureDelete() {
      this.meterList.splice(this.clickIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
      console.log(this.meterList, '删除')
    },
    // 点击删除按钮
    deleteRow(index) {
      this.clickIndex = index
      this.deleteStatus = true
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      this.total = 0
      this.pages.page = 1
      if (this.hadSelectList.length == 0) {
        this.chooseStatus = false
        return
      }
      this.btnLoading = true
      setTimeout(() => {
        if (this.meterList.length > 0) {
          // let select = this.meterList.map(item => item.product_id + item.specification_id)
          // this.hadSelectList = this.hadSelectList.filter(i => {
          //   return !select.includes(i.product_id + i.specification_id)
          // })
          // let arr = [...this.meterList, ...this.hadSelectList]
          this.meterList = JSON.parse(JSON.stringify(this.hadSelectList))
        } else {
          this.meterList = JSON.parse(JSON.stringify(this.hadSelectList))
        }
        this.meterList.forEach((e, i) => {
          this.meterList[i].check_quantity = this.meterList[i].inventory_quantity
          // this.meterList[i].check_quantity = this.meterList[i].check_quantity ? this.meterList[i].check_quantity : 0
          // this.$set(this.listData[i], 'current_unit_price', this.listData[i].current_unit_price ? this.listData[i].current_unit_price : 0)
        })
        this.chooseStatus = false
        this.btnLoading = false
      }, 30)
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list
      for (let i = 0; i < this.hadSelectList.length; i++) {
        this.hadSelectList[i].valid_period = this.hadSelectList[i].valid_period ? this.$moment(this.hadSelectList[i].valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : null
        // 判断是录入的是年月还是年月日
        this.hadSelectList[i].valid_period = this.hadSelectList[i].valid_period ? this.$utils.Timetransformation(this.hadSelectList[i].valid_period) : ''
      }
    },
    // 点击全选是
    selectChangeAll(list) {
      this.btnLoading = true
      let query = {
        warehouse_id: this.warehouse_id,
        product_id: this.queryFrom.product_id,
        specification_id_str: this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : '',
        lookup: '1',
      }
      this.$http.get(this.$apiConsign.inventoryProductLookup, query, false).then(res => {
        if (res.data.length > 0) {
          this.btnLoading = false
          this.hadSelectList = res.data
          for (let i = 0; i < this.hadSelectList.length; i++) {
            // 判断是录入的是年月还是年月日
            this.hadSelectList[i].valid_period = this.hadSelectList[i].valid_period ? this.$moment(this.hadSelectList[i].valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : null
            this.hadSelectList[i].valid_period = this.hadSelectList[i].valid_period ? this.$utils.Timetransformation(this.hadSelectList[i].valid_period) : null
          }
        } else {
          this.$Message.warning('查询暂无数据')
        }
      })
    },
    // 点击查询按钮 查询弹窗
    queryProductLookup() {
      if (this.warehouse_id < 0 || !this.warehouse_id) {
        this.$Message.warning('请先选择仓库名称后再查询')
        return
      }
      this.chooseStatus = true
      this.resultLoading = true
      let query = {
        warehouse_id: this.warehouse_id,
        product_id: this.queryFrom.product_id,
        specification_id_str: this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : '',
        lookup: '1',
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.$http.get(this.$apiConsign.inventoryProductLookup, query, false).then(res => {
        this.resultLoading = false
        if (res.data.length > 0) {
          this.hadSelectList = []
          this.chooseList = res.data
          this.total = res.total
        } else {
          this.$Message.warning('查询暂无数据')
        }
      })
    },
    // 查询产品
    queryProduct(id = null, subId = null) {
      this.$http.get(this.$apiConsign.inventoryProductLookup, { product_id: subId, warehouse_id: this.warehouse_id }, false).then(res => {
        this.productList = res.data
      })
    },
    // 查询sku
    querySku(subId) {
      this.$http.get(this.$apiConsign.inventoryCodeLookup, { product_id: subId, warehouse_id: this.warehouse_id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.specification_id_str = []
      this.modelList = []
      if (!e) return
      this.querySku(e)
    },
    // 输入框改变
    changeIpt(e, name, index) {
      this.$set(this.meterList[index], name, e)
      let total = this.meterList[index].product_amount * this.meterList[index].product_unit_price
      this.$set(this.meterList[index], 'product_total_price', total > 0 ? total.toFixed(2) : 0)
    },
    // 保存
    save() {
      if (!this.warehouse_id) {
        this.$Message.warning('请选择仓库名称')
        return
      }
      if (this.meterList.length == 0) {
        this.$Message.warning('请至少添加一个产品')
        return
      }
      for (let i = 0; i < this.meterList.length; i++) {
        if (!this.meterList[i].check_quantity && this.meterList[i].check_quantity !== 0) {
          this.$Message.warning(`请完善第${i + 1}组产品的实际盘存数量`)
          return
        }
        this.meterList[i].valid_period = this.meterList[i].valid_period ? this.meterList[i].valid_period.replace(/-/gi, '') : null
        this.meterList[i].production_date = this.meterList[i].production_date ? this.$moment(this.meterList[i].production_date * 1000).format('YYYY-MM-DD HH:mm:ss') : null
        this.meterList[i].production_date = this.meterList[i].production_date ? this.$utils.Timetransformation(this.meterList[i].production_date).replace(/-/gi, '') : null
      }
      for (let i = 0; i < this.storeList.length; i++) {
        if (this.warehouse_id == this.storeList[i].warehouse_id) {
          this.postFrom.warehouse_name = this.storeList[i].warehouse_name
        }
      }

      this.postStatus = true
    },
    // 最终确认提交
    surePost() {
      this.postFrom.line_info = JSON.parse(JSON.stringify(this.meterList))
      this.postFrom.warehouse_id = this.warehouse_id
      this.$http.post(this.$apiConsign.queryInventory, this.postFrom, true).then(res => {
        this.$Message.success('提交成功')
        this.postStatus = false
        this.$router.go(-1)
      })
      sessionStorage.setItem('updataCache', '0')
    },
  },
}
</script>

<style scoped lang="less">
.marginL {
  margin-left: 20px;
}
.ivu-table .red td {
  background-color: #2db7f5;
  color: #fff;
}
.ivu-table .demo-table-info-row td {
  background-color: #2db7f5 !important;
}
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
    .tipsDiv {
      margin-top: 20px;
      border: 1px solid #e8eaec;
      height: 230px;
      display: flex;
      .tipsFl {
        width: 210px;
        line-height: 230px;
        padding-left: 33px;
        border-right: 1px solid #e8eaec;
        background: #f8f8f9;
        color: #525b6d;
        font-size: 14px;
      }
      /deep/ .tipsFr {
        flex: 1;
        display: flex;
        padding: 8px 15px 8px 23px;
        .ivu-input-wrapper {
          height: 100% !important;
        }
        .ivu-input {
          height: 100% !important;
        }
      }
    }
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
